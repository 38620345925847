import _slicedToArray from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable no-template-curly-in-string */
import axios from './axios';
import query from 'query-string';
import { api } from './app.config';
import { isArray, isObject } from '../helpers/javascript';
import { create } from './team.services';
import { gtmFireEvent } from '../helpers/gtm';
import translate from '../data/translations';
import { mailExistProviderError } from '../helpers/auth'; // eslint-disable-next-line import/no-cycle

import { plans } from './stripe.services';
export var logged = function logged() {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (error) {}

  return null;
};
export var getGoogleSheetToken = function getGoogleSheetToken() {
  try {
    return JSON.parse(localStorage.getItem('google-sheet-token'));
  } catch (error) {}

  return null;
}; // Get header for api

export var getAuthHeader = function getAuthHeader() {
  var user = logged();
  return {
    Authorization: "Bearer ".concat(user ? user.jwt : process.env.API_DEFAULT_TOKEN),
    'X-Mission-Control-Team': user ? user.team : process.env.API_DEFAULT_TEAM
  };
};
export var logout = function logout() {
  return new Promise(function (resolve) {
    localStorage.removeItem('user');
    localStorage.removeItem('woxo-current-widget');
    if (window.Woxo && window.Woxo.Suscription && window.Woxo.Suscription.plan) window.Woxo.Suscription.plan = false;
    gtmFireEvent({
      event: 'onSecurity',
      category: 'security',
      action: 'logout',
      label: 'user logout action'
    });
    gtmFireEvent({
      event: 'userLogout'
    });
    resolve();
  });
};
export var setProfile = function setProfile(_ref, headers) {
  var name = _ref.name,
      username = _ref.username,
      email = _ref.email,
      password = _ref.password,
      rePassword = _ref.rePassword,
      profession = _ref.profession,
      entityType = _ref.entityType;
  return new Promise(function (resolve, reject) {
    var audience = localStorage.getItem('user-audience');
    axios({
      url: "".concat(api, "/custom/profiles/user"),
      method: 'POST',
      headers: headers && headers.jwt && headers.team ? {
        Authorization: "Bearer ".concat(headers.jwt),
        'X-Mission-Control-Team': headers.team
      } : getAuthHeader(),
      data: {
        name: name,
        username: username,
        email: email,
        password: password,
        rePassword: rePassword,
        profession: profession,
        entityType: entityType,
        audience: audience
      }
    }).then(function (response) {
      var user = logged();
      if (response.data.success) resolve(_objectSpread(_objectSpread({}, response.data.success), {}, {
        jwt: headers && headers.jwt ? headers.jwt : user && user.jwt || null
      }));else {
        var error = new Error();
        error.type = response.data.error;
        reject(error);
      }
    })["catch"](function (e) {
      reject(e);
    });
  });
};
export var getProfile = function getProfile(token, team) {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/user"),
      headers: {
        Authorization: "Bearer ".concat(token),
        'X-Mission-Control-Team': team
      }
    }).then(function (response) {
      resolve(response.data);
    })["catch"](function () {
      resolve({});
    });
  });
};
export var checkUserSession = function checkUserSession() {
  var user = logged();

  if (!user) {
    return Promise.resolve(false);
  }

  return axios({
    url: "".concat(api, "/custom/profiles/user"),
    headers: {
      Authorization: "Bearer ".concat(user.jwt),
      'X-Mission-Control-Team': user.team
    }
  }).then(function () {
    return true;
  })["catch"](function () {
    return false;
  });
};
/**
 * Some social network like facebook needs extra reuest for retrieve email
 */

var retrieveEmail = function retrieveEmail(_ref2) {
  var params = _ref2.params,
      provider = _ref2.provider;
  return new Promise(function (resolve, reject) {
    if (provider === 'google') return resolve(params['id_token[payload][email]']);
    if (provider === 'local') return resolve(params.email); // Default facebook retrieve email

    return axios({
      url: "https://graph.facebook.com/me?fields=email&access_token=".concat(params.access_token)
    }).then(function (response) {
      resolve(response.data.email);
    })["catch"](function () {
      reject();
    });
  });
};
/**
 * Check if user email exist in api
 */


export var checkUser = function checkUser(_ref3) {
  var email = _ref3.email;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/teams/user/").concat(email)
    }).then(function (response) {
      resolve(response.data.provider);
    })["catch"](function () {
      reject();
    });
  });
};
/**
 * Check user o email status
 */

var checkCredentials = function checkCredentials(_ref4) {
  var identifier = _ref4.identifier;
  return new Promise(function (resolve, reject) {
    checkUser({
      email: identifier
    }).then(function (provider) {
      if (provider === false) reject(new Error(translate('error.not-found.user-or-email', 'en')));
      if (provider === 'local') reject(new Error(translate('error.incorrect.password', 'en')));else reject(new Error(translate("error.mail.exist.provider.".concat(provider), 'en')));
    })["catch"](function () {
      reject(new Error(translate('error.credentials.not-found', 'en')));
    });
  });
};

export var register = function register(_ref5) {
  var provider = _ref5.provider,
      data = _ref5.data;
  return new Promise(function (resolve, reject) {
    var location = window.location.search;
    var config = {};
    if (provider !== 'local') config = {
      url: "".concat(api, "/auth/").concat(provider, "/callback").concat(location)
    };else config = {
      url: "".concat(api, "/auth/local/register"),
      method: 'POST',
      data: data,
      timeout: 30000
    };
    axios(config).then(function (res) {
      var audience = localStorage.getItem('user-audience');
      gtmFireEvent({
        event: 'onSecurity',
        category: audience === 'video' ? 'video-maker' : 'widgets',
        action: "register-".concat(audience),
        label: "".concat(provider, "-register-2")
      });
      gtmFireEvent({
        event: 'onSecurity',
        category: 'security',
        action: 'register',
        label: "".concat(provider, "-register-2")
      });
      resolve(res.data);
    })["catch"](function (e) {
      if (e.response) {
        var res = e.response ? e.response.data : {};

        if (isArray(res.message) && isObject(res.message[0]) && isArray(res.message[0].messages) && isObject(res.message[0].messages[0])) {
          gtmFireEvent({
            event: 'onSecurity',
            category: 'security',
            action: 'register-error',
            label: 'user email already exist'
          });

          if (res.message[0].messages[0].id === 'Auth.form.error.email.taken') {
            var params = provider !== 'local' ? query.parse(location) : {
              email: data.email
            };
            retrieveEmail({
              provider: provider,
              params: params
            }).then(function (email) {
              return checkUser({
                email: email
              });
            }).then(function (check) {
              reject(new Error(mailExistProviderError({
                provider: check
              })));
            })["catch"](function () {
              reject(new Error(translate('mailExistError', 'en')));
            });
          } else if (res.message[0].messages[0].id === 'Auth.form.error.username.taken') reject(new Error(translate('usernameExistError', 'en')));
        } else if (res.message && res.message.message === 'Email was not available.') reject(new Error(translate('error.credentials.not-found', 'en')));else {
          gtmFireEvent({
            event: 'onSecurity',
            category: 'security',
            action: 'register-error',
            label: "Couldn't login app into api"
          });
          reject(new Error(translate('error.network', 'en')));
        }
      } else reject(new Error(translate('error.network', 'en')));
    });
  });
};
export var auth = function auth(_ref6) {
  var identifier = _ref6.identifier,
      password = _ref6.password;
  return new Promise(function (resolve, reject) {
    axios({
      method: "POST",
      url: "".concat(api, "/auth/local"),
      data: {
        identifier: identifier,
        password: password
      },
      timeout: 30000
    }).then(function (response) {
      return Promise.all([response.data, create(response.data)]);
    }).then(function (_ref7) {
      var _ref8 = _slicedToArray(_ref7, 2),
          response = _ref8[0],
          team = _ref8[1];

      return Promise.all([response, team, getProfile(response.jwt, team._id), plans({
        headers: {
          Authorization: "Bearer ".concat(response.jwt),
          'X-Mission-Control-Team': team._id
        }
      })]);
    }).then(function (_ref9) {
      var _ref10 = _slicedToArray(_ref9, 4),
          response = _ref10[0],
          team = _ref10[1],
          extra = _ref10[2],
          plan = _ref10[3];

      var user = {
        jwt: response.jwt,
        username: response.user.username,
        email: response.user.email,
        team: team._id,
        branding: isArray(team.brandings) && team.brandings[0] || null,
        profile: extra.profile,
        provider: extra.provider,
        confirmed: response.user.confirmed
      };

      if (response.user && response.user.disabled) {
        var error = new Error('Your account has been deleted by yourself. Click {message.here} to recover it');
        error.user = user;
        return Promise.reject(error);
      }

      return Promise.all([user, plan]);
    }).then(function (_ref11) {
      var _ref12 = _slicedToArray(_ref11, 2),
          user = _ref12[0],
          plan = _ref12[1];

      gtmFireEvent({
        event: 'onSecurity',
        category: 'security',
        action: 'login',
        label: 'User login'
      });
      resolve({
        user: user,
        plan: plan
      });
    })["catch"](function (e) {
      gtmFireEvent({
        event: 'onSecurity',
        category: 'security',
        action: 'login-error',
        label: 'User login error'
      });
      if (e.user) return reject(e);
      if (!e.response) return reject(new Error(translate('error.network', 'en')));
      return checkCredentials({
        identifier: identifier
      })["catch"](function (error) {
        return reject(error);
      });
    });
  });
};
export var activate = function activate(_ref13) {
  var code = _ref13.code;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/activate"),
      method: 'POST',
      data: {
        code: code
      }
    }).then(function (response) {
      if (response.data.success) resolve();else if (response.data.error) reject(new Error(translate(response.data.error)));else reject(new Error(translate('error.network')));
    })["catch"](function () {
      reject(new Error(translate('error.network')));
    });
  });
};
export var disable = function disable() {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/activate"),
      method: 'DELETE',
      headers: getAuthHeader()
    }).then(function (response) {
      if (response.data.success) resolve();else reject();
    })["catch"](function () {
      reject();
    });
  });
};
export var verifyUserCode = function verifyUserCode(_ref14) {
  var code = _ref14.code;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/confirm"),
      method: 'POST',
      data: {
        code: code
      }
    }).then(function (response) {
      if (response.data.success) resolve();else reject(new Error(translate(response.data.error)));
    })["catch"](function () {
      reject(new Error(translate('error.network')));
    });
  });
};
export var sendCode = function sendCode(_ref15) {
  var redirectUrl = _ref15.redirectUrl;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/sendEmailConfirmation"),
      method: 'POST',
      headers: getAuthHeader(),
      data: {
        redirectUrl: redirectUrl
      }
    }).then(function (response) {
      if (response.data.success) resolve();else reject(new Error(translate(response.data.error || 'error.network')));
    })["catch"](function () {
      reject(new Error(translate('error.network')));
    });
  });
};
export var sendRecoveryCode = function sendRecoveryCode(_ref16) {
  var headers = _ref16.headers;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/sendRecoveryCode"),
      method: 'POST',
      headers: headers
    }).then(function (response) {
      if (response.data.success) resolve();else reject(new Error(translate(response.data.error || 'error.network')));
    })["catch"](function () {
      reject(new Error(translate('error.network')));
    });
  });
};
export var forgotPassword = function forgotPassword(email) {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/forgot-password"),
      method: 'POST',
      data: {
        email: email
      }
    }).then(function (response) {
      if (response.data.success) resolve();else reject(new Error(translate(response.data.error || 'error.network')));
    })["catch"](function () {
      reject(new Error(translate('error.network')));
    });
  });
};
export var resetPassword = function resetPassword(_ref17) {
  var code = _ref17.code,
      password = _ref17.password,
      rePassword = _ref17.rePassword;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/reset-password"),
      method: 'POST',
      data: {
        code: code,
        password: password,
        rePassword: rePassword
      }
    }).then(function (response) {
      if (response.data.success) resolve(response.data.success);else reject(new Error(translate(response.data.error || 'error.network').replace('resend link', '{message.resend.link}')));
    })["catch"](function () {
      reject(new Error(translate('error.network').replace('resend link', '{message.resend.link}')));
    });
  });
};
export var isRoot = function isRoot() {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/custom/profiles/root"),
      headers: getAuthHeader(),
      method: 'POST'
    }).then(function (response) {
      if (response.data.success) resolve();else reject();
    })["catch"](function () {
      reject();
    });
  });
};