import axios from 'axios';
import { logout } from '../security.service';
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error && error.response && error.response.status === 403 && error.response.statusText === 'Forbidden') {
    localStorage.removeItem('user');
    localStorage.removeItem('woxo-current-widget');
    if (window.Woxo && window.Woxo.Suscription && window.Woxo.Suscription.plan) window.Woxo.Suscription.plan = false;
    window.location.reload();
  }

  return Promise.reject(error);
});
export default axios;